import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import OurWorkslef from "../components/hire/OurWorkself";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `EdTech Design Agency`,
    text: "Innovative EdTech Designs",
    content: "We are an experienced EdTech Design Agency designing engaging and innovative EdTech platforms for exceptional learning experiences. With our custom EdTech design services, we deliver user-friendly and seamless learning solutions tailored to meet the evolving needs of educators and learners.",
    list: [
      'User-Focused Designs',
      'Data-Driven Approach',
      'Personalized Learning Experiences'
    ]
  };
  const ourData = {
    title: "Client’s Opinion On Our EdTech Design Services",
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['visa.webp'],
        stars: 5,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
    ]
  };
  const commonContent = {
    title: "Empower Learning With Our <span class='h1-span'>EdTech Design Company</span>",
    content: [
      {
        title: "10+ EdTech Designers",
        text: "We have a team of 10+ designers experienced in designing for EdTech industry. Our edtech designers deliver innovative and effective learning solutions tailored to your needs.",
      },
      {
        title: "300+ Modules Implemented",
        text: "Our EdTech Design Agency has successfully implemented over 300+ interactive learning modules that enhance educational experiences with engaging content.",
      },
      {
        title: "120+ Projects Completed",
        text: "With our 120+ completed EdTech projects, our EdTech design agency highlights reliability and competence and ensures high-quality outcomes for every project.",
      },
      {
        title: "Trusted by 100+ Clients",
        text: "More than 100+ clients trust our EdTech design company for their educational technology needs as we continue to prioritize client satisfaction and expectations.",
      },
    ],
  };
  const commonContent1 = {
    title: "Achieve More With Our EdTech Design Agency",
    content: [
      {
        title: "Industry Expertise",
        text: "Our edtech design company has extensive experience in the edtech industry, allowing us to deeply understand the unique needs and challenges faced by edtech companies. ",
      },
      {
        title: "Data-Driven Approach",
        text: "Our edtech design services leverage learning analytics and performance metrics to optimize edtech solutions, driving measurable improvements continuously.",
      },
      {
        title: "User-Centered Design",
        text: "Our edtech designs prioritize users' needs and craft intuitive and accessible interfaces that foster seamless interaction and enhance the learning experience.",
      },
      {
        title: "Adaptive Learning Solution",
        text: "We deliver personalized and adaptive learning experiences, leveraging data analytics to create experiences that cater to each user's unique needs and preferences.",
      },
      {
        title: "Responsiveness",
        text: "Our edtech design agency creates designs with a mobile-first and responsive approach to ensure that your platform remains consistent and fully functional across devices.",
      },
      {
        title: "Continuous Innovation",
        text: "We incorporate emerging trends and cutting-edge features into our solutions, ensuring your edtech platform remains innovative, engaging, and future-proof.",
      },
    ],
  };
  const Questions = [
    "What EdTech design services do you offer?",
    "How long does an Edtech design project take?",
    "Why should I choose Octet for the EdTech design project?",
    "How can we get started with your EdTech Design Services?"
  ];
  const faqData = [
    {
        list: [
            `<span><h4 class="inline">Edtech App Design:</h4></span> We craft intuitive and visually appealing mobile apps that enhance your users' learning experience. These apps feature seamless navigation and interactive features.`,
            `<span><h4 class="inline">Edtech Web Design:</h4></span> We design user-centric websites optimized for educational purposes, facilitating easy access to resources, courses, and collaboration tools.`,
            `<span><h4 class="inline">E-learning Platform Design:</h4></span> We create comprehensive online learning platforms that foster engagement and interactivity, supporting various content formats and learning activities.`,
            `<span><h4 class="inline">Edtech Dashboard Design:</h4></span> We develop user-friendly dashboards that give educators and administrators insights into learner progress, course performance, and other vital metrics.`,
            `<span><h4 class="inline">UX Audit for Edtech Apps:</h4></span> We also thoroughly evaluate your existing Edtech applications to identify usability issues, enhance user experience, and optimize performance.`,
            `<span><h4 class="inline">LMS Website Design:</h4></span> We design and customize Learning Management System (LMS) websites to streamline course management, content delivery, and communication within educational institutions.`,
            `<span><h4 class="inline">Custom Platform Design:</h4></span> We tailor edtech platforms to your requirements, ensuring scalability, flexibility, and alignment with educational objectives.`
        ]
    },
    {
      para: [
        `The duration of our edtech design project can vary depending on the complexity of your project, the platform's scope, and the specific features and functionalities you want us to develop.`,
        `Our edtech design projects typically last 3 to 6 months, ensuring that we deliver a comprehensive and well-crafted solution tailored to your needs.`,
        `Our experienced designers will work closely with you to understand your vision, gather requirements, create prototypes, and refine the design until it meets your expectations. <a href="/contact-us/" title="Contact us" target="_blank">Contact us</a> to hire edtech designers!`
      ],
    },
    {
      para: [
        `We are a leading EdTech UI UX design agency with deep industry expertise. We use a data-driven approach and user-centered design methodology to deliver adaptive learning solutions that are responsive, innovative, and tailored to your unique needs. `,
        `Our commitment to continuous improvement and our ability to leverage the latest technologies ensure that your edtech platform remains at the forefront of the industry.`,
        `Furthermore, our collaborative and transparent work process guarantees a seamless experience, allowing you to focus on your core business while we design your edtech solution.`
      ]
    },
    {
      para: [
        `To get started with our edtech design services, simply <a href="/contact-us/" title="Contact us" target="_blank">contact us</a>.`,
        `Our streamlined onboarding process begins with an initial consultation to understand your requirements, goals, and challenges.`
      ],
    },
  ];
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
         {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "What EdTech design services do you offer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Edtech App Design
          We craft intuitive and visually appealing mobile apps that enhance your users' learning experience. These apps feature seamless navigation and interactive features.
          Edtech Web Design
          We design user-centric websites optimized for educational purposes, facilitating easy access to resources, courses, and collaboration tools.
          E-learning Platform Design
          We create comprehensive online learning platforms that foster engagement and interactivity, supporting various content formats and learning activities.
          Edtech Dashboard Design
          We develop user-friendly dashboards that give educators and administrators insights into learner progress, course performance, and other vital metrics.
          UX Audit for Edtech Apps
          We also thoroughly evaluate your existing Edtech applications to identify usability issues, enhance user experience, and optimize performance.
          LMS Website Design
          We design and customize Learning Management System (LMS) websites to streamline course management, content delivery, and communication within educational institutions.
          Custom Platform Design
          We tailor edtech platforms to your specific requirements, ensuring scalability, flexibility, and alignment with educational objectives."
              }
            },{
              "@type": "Question",
              "name": "How long does an Edtech design project take?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The duration of our edtech design project can vary depending on the complexity of your project, the platform's scope, and the specific features and functionalities you want us to develop. 
          
          Our edtech design projects typically last 3 to 6 months, ensuring that we deliver a comprehensive and well-crafted solution tailored to your needs. 
          
          Our experienced designers will work closely with you to understand your vision, gather requirements, create prototypes, and refine the design until it meets your expectations. Contact us to hire edtech designers!"
              }
            },{
              "@type": "Question",
              "name": "Why should I choose Octet for the EdTech design project?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We are a leading EdTech UI UX design agency with deep industry expertise. We use a data-driven approach and user-centered design methodology to deliver adaptive learning solutions that are responsive, innovative, and tailored to your unique needs. 
          
          Our commitment to continuous improvement and our ability to leverage the latest technologies ensure that your edtech platform remains at the forefront of the industry. 
          Furthermore, our collaborative and transparent work process guarantees a seamless experience, allowing you to focus on your core business while we design your edtech solution."
              }
            },{
              "@type": "Question",
              "name": "How can we get started with your EdTech Design Services?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "To get started with our edtech design services, simply contact us. 
          
          Our streamlined onboarding process begins with an initial consultation to understand your specific requirements, goals, and challenges."
              }
            }]
          }                                               
        `}
      </script>
    </Helmet>
    <Layout hire="EdTech Design Agency" hotjarHire={true} footerText="Maximize your conversions with Us">
      <div>
        <HireBanner data={banner} hire pageName="Hire Conversion Rate Optimisation" />
        <section className="py-[40px]">
          <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
            <div className="lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <OurWorkslef data={ourData} hire />
        <CommonServiceComponent data={commonContent} mode={"dark"} hire />
        <CommonServiceComponent data={commonContent1} mode={"light"} hire />
        <Faq grey section Questions={Questions} faqData={faqData} des={''} />
      </div>
    </Layout>
    </>
  );
};

export default HirePage;

export const Head = () => (
  <Seo
    title="EdTech Design Agency | Best EdTech Design"
    description="We are a leading EdTech design agency designing innovative solutions with cutting-edge EdTech design services for seamless learning experiences."
  />
);
